import React from "react"
import { Link } from "gatsby"

import { gradientPicker, btnColorPicker } from "../components/layout"

// import LogoOnlyGray from "../../static/images/DAIQUIRI_logo_only_gray-01.svg"
// import LogoOnlyWhite from "../../static/images/DAIQUIRI_logo_only_white-01.svg"
import LogoOnlyPrimary from "../../static/images/DAIQUIRI_logo_only-01.svg"

const Posts = ({ edges, imageMap, defaultImage }) => {
  return (
    <div className="mx-n2 d-flex flex-wrap">
      {edges.map((edge, index) => (
        <Link
          key={index}
          to={`/posts/${edge.node.fields.slug}`}
          className="d-block m-auto m-sm-0 py-1 px-2 flex-grow-0 flex-shrink-0 post-cards"
        >
          <div className="card h-100 shadow">
            <div
              className="position-relative card-img-top"
              style={{ height: 160 }}
            >
              <div className="position-absolute m-3 p-top p-left">
                <LogoOnlyPrimary style={{ height: 28 }} />
              </div>
              <div
                className={`position-absolute p-bottom p-right p-left ${gradientPicker(
                  edge.node.frontmatter.category
                )} d-flex text-center flex-column justify-content-center`}
                style={{ height: 50 }}
              >
                <h3 className="m-0 text-white" style={{ letterSpacing: 2 }}>
                  {edge.node.frontmatter.category.toUpperCase()}
                </h3>
              </div>
            </div>
            {/* <img
              className="card-img-top gradient-bg"
              style={{ height: 160, objectFit: "cover" }}
              src={
                imageMap[edge.node.fields.slug] ||
                imageMap[
                  "cat-" + edge.node.frontmatter.category.toLowerCase()
                ] ||
                imageMap[defaultImage]
              }
              alt={edge.node.fields.slug}
            /> */}
            <div className="card-body d-flex flex-column">
              <h2 className="card-title my-0">{edge.node.frontmatter.title}</h2>
              <h6 className="card-title text-muted">{`${edge.node.frontmatter.date} // ${edge.node.timeToRead} min`}</h6>
              <p className="card-text mt-2 mb-auto text-normal">
                {edge.node.frontmatter.summary || edge.node.excerpt}
              </p>
              <div className="mt-2 mt-md-5 fs-sm text-center">
                <button
                  className={`btn btn-sm btn-rounded ${btnColorPicker(
                    edge.node.frontmatter.category
                  )} shadow-none`}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        </Link>

        // <div
        //   className="m-1 flex-grow-1 flex-shrink-1 bg-white"
        //   style={{ flexBasis: "30%" }}
        //   key={index}
        // >
        //   <Link to={`/post/${edge.node.fields.slug}`}>
        //     <div className="row no-gutters border overflow-hidden flex-row shadow-sm position-relative h-100">
        //       <div className="col p-4 d-flex flex-column position-static">
        //         <div className="h4 condensed fw-bold text-primary">
        //           {edge.node.frontmatter.category.toUpperCase()}
        //         </div>
        //         <h2 className="mb-0">{edge.node.frontmatter.title}</h2>
        //         <div className="h6 my-1 text-muted">
        //           {`${edge.node.frontmatter.date} // ${edge.node.timeToRead} min`}
        //         </div>
        //         <p className="card-text mt-2 mb-auto text-dark">
        //           {edge.node.frontmatter.summary || edge.node.excerpt}
        //         </p>
        //         <p className="mt-2 mt-md-auto">&gt; Continue reading</p>
        //       </div>
        //       {imageMap[edge.node.fields.slug] != null && (
        //         <div className="col-5 d-none d-md-block">
        //           <img
        //             src={imageMap[edge.node.fields.slug]}
        //             title={edge.node.frontmatter.title}
        //             alt={edge.node.frontmatter.title}
        // style={{
        //   objectFit: "cover",
        //   width: "100%",
        //   height: "100%",
        // }}
        //           />
        //         </div>
        //       )}
        //     </div>
        //   </Link>
        // </div>
      ))}
    </div>
  )
}

export default Posts

// const Post = ( {edge } ) => {
//   return (
//     <div>

//     </div>
//   )
// }
