import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Posts from "../components/posts"

const Blog = () => {
  const queryResult = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, filter: {fileAbsolutePath: {regex: "/posts\\/d_blog\\//"}}) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              date
              title
              featured
              category
              summary
            }
            excerpt(truncate: true)
            timeToRead
          }
        }
      }
      allFile(filter: {ext: {ne: ".md"}, dir: {regex: "/\\/posts/"}}) {
        nodes {
          publicURL
          name
        }
      }
    }
  `)

  const [filter, setFilter] = React.useState("All")

  const allEdges = queryResult.allMarkdownRemark.edges
  const filteredEdges = allEdges.filter(
    edge => filter === "All" || edge.node.frontmatter.category === filter
  )
  const allNonMdFilesInRepo = queryResult.allFile.nodes
  const imageMap = allNonMdFilesInRepo.reduce((map, node) => {
    map[node.name] = node.publicURL
    return map
  }, {})

  const allCategories = [
    "All",
    ...allEdges.reduce((set, edge) => {
      return set.add(edge.node.frontmatter.category || "general")
    }, new Set()),
  ]

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="container px-0">
        <div className="h2 my-3 px-2 px-sm-0">{`${filter === 'All' ? "All Blog Posts" : `Blog - ${filter}`}`}</div>
        <div className="pb-2 pt-1">
          {allCategories.map(cat => (
            <button
              key={cat}
              className={`btn ${
                filter === cat ? "btn-primary" : "btn-outline-primary"
              } btn-rounded btn-sm ml-1 mt-1 shadow-none`}
              onClick={() => setFilter(cat)}
            >
              {cat}
            </button>
          ))}
          {/* <Link to={`/posts`}>
            <button className="btn btn-outline-primary float-right">
              View All
            </button>
          </Link> */}
        </div>
        <Posts edges={filteredEdges} imageMap={imageMap} defaultImage="cat-general" />
      </div>
    </Layout>
  )
}

export default Blog
